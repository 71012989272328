import html2pdf from 'html2pdf.js';

const downloadPDF = (filename,ori) =>{
  let defOri = ori=='p'?'portrait': ori=='l'&&'landscape'
  let yourDate = new Date()
  yourDate= yourDate.toISOString().split('T')[0]
  const source = document.getElementById("download-section");
    var w = source.offsetWidth;
  var h = source.offsetHeight;
  var opt = {
    margin: 2,
    filename: filename+"-"+yourDate+".pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, dpi: 400,},
    pagebreak: { avoid: ".tableInside tr"},
    // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    jsPDF: { unit: "mm", format: "a4", orientation: defOri}
  
  };
  html2pdf().from(source).set(opt).save();
  // var w = source.offsetWidth;
  // var h = source.offsetHeight;



}

export default downloadPDF;