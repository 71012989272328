import React, { Component } from "react";
import { AddCircleOutline, Delete, FormatColorReset } from "@material-ui/icons";
import { Grid, TextField, Button, IconButton } from "@material-ui/core";
import _ from "lodash";
import Notifications from "react-notification-system-redux";
// import { addMaterialList } from "../../Redux/projectRedux/projectAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import DisplayMaterial from "./DisplayMaterial";
import {
  addMaterialList,
  addEquipmentList,
  addManforceList,
} from "../../Redux/storeRedux/storeAction";
//import { addMaterialList,addEquipmentList,addManforceList} from "../../Redux/storeRedux/storeAction"
import DisplayManPower from "./DisplayManPower";
import DisplayEquipment from "./DisplayEquipment";

class AddMaterialList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materialCount: 1,
      materials: [],
      itemName: "",
      exportdata: [],
      store: [],
      errormsg: "",
      errorindex: [],
      // isAdded: false,
    };
  }
  handleExport = () => {
    const newExportData = [];
    let counter = 1;
    if (this.state.itemName === "Material") {
      this.props.storeData.forEach(item => {
        newExportData.push({
          "sr.no": counter.toString(),
          itemname: item.materialName,
          unit: item.materialUnit,
          rate: item.materialRate,
        });
        counter++;
      });
    } else if (this.state.itemName === "Equipment") {
      this.props.equipmentData.forEach(item => {
        newExportData.push({
          "sr.no": counter,
          itemname: item.equipmentName,
          unit: item.equipmentUnit,
          rate: item.equipmentRate,
        });
        counter++;
      });
    } else if (this.state.itemName === "ManPower") {
      this.props.manpowerData.forEach(item => {
        newExportData.push({
          "sr.no": counter,
          itemname: item.manpowerName,
          unit: item.manpowerUnit,
          rate: item.manpowerRate,
        });
        counter++;
      });
    }
    this.setState({
      exportdata: newExportData,
    });
  };
  handleExportFile = () => {
    this.setState({
      exportdata: [],
    });
  };
  changeHandler = event => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        results.data.map(d => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        const newImportedItem = [];
        valuesArray.forEach(item => {
          if (this.state.itemName === "Material") {
            newImportedItem.push({
              materialName: item[1],
              materialUnit: item[2],
              materialRate: item[3],
            });
          } else if (this.state.itemName === "Equipment") {
            newImportedItem.push({
              equipmentName: item[1],
              equipmentUnit: item[2],
              equipmentRate: item[3],
            });
          } else if (this.state.itemName === "ManPower") {
            newImportedItem.push({
              manpowerName: item[1],
              manpowerUnit: item[2],
              manpowerRate: item[3],
            });
          }
        });
        console.log("new imported items", newImportedItem);
        if (this.state.itemName === "Material")
          this.props.addMaterialList(newImportedItem);
        if (this.state.itemName === "Equipment")
          this.props.addEquipmentList(newImportedItem);
        //  if(this.state.itemName==="Labour") this.props.addLabourList(newImportedItem);
        if (this.state.itemName === "ManPower")
          this.props.addManforceList(newImportedItem);
        window.location.reload();
      }.bind(this),
    });
  };
  headers = [
    { label: "Sr no", key: "sr.no" },
    { label: "MATERIAL", key: "itemname" },
    { label: "UNIT", key: "unit" },
    { label: "RATE", key: "rate" },
  ];
  async componentDidMount() {
    // console.log("index's props are here",this.props)
    if (this.props.path === "/addMaterial") {
      this.setState({
        itemName: "Material",
        materials:[{
          materialName:"",materialUnit:"",materialRate:""
        }]
      });
      this.headers[1].label = "MATERIAL";
    } else if (this.props.path === "/addEquipment") {
      this.setState({
        itemName: "Equipment",
        materials:[{
          equipmentName:"",equipmentUnit:"",equipmentRate:""
        }]
      });
      this.headers[1].label = "EQUIPMENT";
    } else if (this.props.path === "/addManpower") {
      this.setState({
        itemName: "ManPower",
        materials:[{
          manpowerName:"",manpowerUnit:"",manpowerRate:""
        }]
      });
      this.headers[1].label = "MANPOWER";
    }
  }
  refreshPage = () => {
    window.location.reload(false);
  };

  deleteMaterial = i => {
    if (this.state.materialCount === 1) {
      alert(`atleast 1 ${this.state.itemName} is required`);
      return;
    }
    var arr = [...this.state.materials];
    var error_arr=[...this.state.errorindex]
    arr.splice(i, 1);
    error_arr.splice(i,1)
    this.setState({
      errorindex:error_arr,
      materials: arr,
      materialCount: this.state.materialCount - 1,
    });
  };

  addMaterial =  (event, index) => {
    console.log("this st material state at top ",this.state.materials)
    const prev = [...this.state.materials];
    if (this.state.itemName === "Material") {
      let found = false;
      this.props.storeData.forEach((material, i) => {
        if (material.materialName.trim() === event.target.value.trim()) {
          found = true;
          this.setState({ errormsg: `please enter valid ${this.state.itemName}`});
          return;
        }
      });
      this.state.materials.forEach((material, i) => {
        if (material.materialName.trim() === event.target.value.trim() &&
        material.materialName !== "" &&
        i !== index) {
          found = true;
          return;
        }
      });
      let err = this.state.errorindex;
      if (found) {
        err[index] = true;
        this.setState({
          errormsg: `please enter valid ${this.state.itemName}`,
           errorindex: err
           });
      }else if(event.target.value.trim().length === 0){
        err[index] = true;
        this.setState({ 
          errorindex: err,
          errormsg:`please enter valid ${this.state.itemName}`
        });
      } else {
        err[index] = false;
        this.setState({ errorindex: err });
      }
      let sameNamearr=[]
      console.log("this is materials state",this.state.materials)
      this.state.materials.forEach( (con,i) =>{
        if(con.materialName.trim() === this.state.materials[index].materialName.trim() && index !=i )
        {
          sameNamearr.push(i);
        }
      })
      console.log("sameNameerror",sameNamearr)
      if(sameNamearr.length >1)
      {
        sameNamearr=[]
      }
      if(sameNamearr.length ===1)
      {
        console.log("checked this ")
        let temp_errorindex=[...this.state.errorindex]
        temp_errorindex[sameNamearr[0]]=false
        console.log("tjhis is",temp_errorindex)
        this.setState({
          errorindex:temp_errorindex
        })
      }
      prev[index] = {
        ...prev[index],
        materialName: event.target.value,
      };
    } else if (this.state.itemName === "Equipment") {
      let found = false;
      this.props.equipmentData.forEach((material, i) => {
        if (material.equipmentName.trim() === event.target.value.trim()) {
          found = true;
          return;
        }
      });
      this.state.materials.forEach((material, i) => {
        if (material.equipmentName.trim() === event.target.value.trim() && 
          material.equipmentName !== "" &&
          i !== index) {
          found = true;
          return;
        }
      });
      let err = this.state.errorindex;
      if (found) {
        err[index] = true;
        this.setState({
           errorindex: err ,
           errormsg: `please enter valid ${this.state.itemName}`,
          });
      }else if(event.target.value.trim().length === 0){
        err[index] = true;
        this.setState({ 
          errorindex: err,
          errormsg:`please enter valid ${this.state.itemName}`
        });
      } else {
        err[index] = false;
        this.setState({ errorindex: err });
      }
      let sameNamearr=[]
    this.state.materials.forEach( (con,i) =>{
      if(con.equipmentName.trim() === this.state.materials[index].equipmentName.trim() && index !=i )
      {
        sameNamearr.push(i);
      }
    })
    console.log("sameNameerror",sameNamearr)
    if(sameNamearr.length >1)
    {
      sameNamearr=[]
    }
    if(sameNamearr.length ===1)
    {
      console.log("checked this ")
      let temp_errorindex=[...this.state.errorindex]
      temp_errorindex[sameNamearr[0]]=false
      console.log("tjhis is",temp_errorindex)
      this.setState({
        errorindex:temp_errorindex
      })
    }
      prev[index] = {
        ...prev[index],
        equipmentName: event.target.value,
        
      };
    } else if (this.state.itemName === "ManPower") {
      let found = false;
      this.props.manpowerData.forEach((material, i) => {
        if (material.manpowerName.trim() === event.target.value.trim()) {
          found = true;
          return;
        }
      });
      this.state.materials.forEach((material, i) => {
        if (material.manpowerName.trim() === event.target.value.trim() &&
          material.manpowerName !== "" &&
          i !== index) {
          found = true;
          return;
        }
      });
      let err = this.state.errorindex;
      if (found) {
        err[index] = true;
        this.setState({ 
          errorindex: err,
          errormsg: `please enter valid ${this.state.itemName}`,
        });

      }else if(event.target.value.trim().length === 0){
        err[index] = true;
        this.setState({ 
          errorindex: err,
          errormsg:`please enter valid ${this.state.itemName}`
        });
      } else {
        err[index] = false;
        this.setState({ errorindex: err });
      }
      let sameNamearr=[]
    this.state.materials.forEach( (con,i) =>{
      if(con.manpowerName.trim() === this.state.materials[index].manpowerName.trim() && index !=i )
      {
        sameNamearr.push(i);
      }
    })
    console.log("sameNameerror",sameNamearr)
    if(sameNamearr.length >1)
    {
      sameNamearr=[]
    }
    if(sameNamearr.length ===1)
    {
      console.log("checked this ")
      let temp_errorindex=[...this.state.errorindex]
      temp_errorindex[sameNamearr[0]]=false
      console.log("tjhis is",temp_errorindex)
      this.setState({
        errorindex:temp_errorindex
      })
    }
      prev[index] = {
        ...prev[index],
        manpowerName: event.target.value,
      };
    }
   this.setState({
      materials: prev,
    });
  };
  setItemUnits = (event, index) => {
    const prev = this.state.materials;
    if (this.state.itemName === "Material")
      prev[index].materialUnit = event.target.value;
    if (this.state.itemName === "Equipment")
      prev[index].equipmentUnit = event.target.value;
    //   if(this.state.itemName==="Labour") prev[index].labourUnit=event.target.value;
    if (this.state.itemName === "ManPower")
      prev[index].manpowerUnit = event.target.value;
    this.setState({
      materals: prev,
    });
  };
  setItemRate = (event, index) => {
    const prev = this.state.materials;
    if (this.state.itemName === "Material")
      prev[index].materialRate = event.target.value;
    if (this.state.itemName === "Equipment")
      prev[index].equipmentRate = event.target.value;
    //  if(this.state.itemName==="Labour") prev[index].labourRate=event.target.value;
    if (this.state.itemName === "ManPower")
      prev[index].manpowerRate = event.target.value;
    this.setState({
      materals: prev,
    });
  };
  //   Check once if it works
  setMaterialCount = () => {
    let prev=[...this.state.materials]
    if (this.state.itemName === "Material")
       {
         prev.push({
          materialName:"",
          materialUnit:"",
          materialRate:""
         })
       }
    else if (this.state.itemName === "Equipment")
    {
      prev.push({
       equipmentName:"",
       equipmentUnit:"",
       equipmentRate:""
      })
    }
    else if (this.state.itemName === "Labour")
    {
      prev.push({
       labourName:"",
       labourUnit:"",
       labourRate:""
      })
    }
    else if (this.state.itemName === "ManPower")
    {
      prev.push({
       manpowerName:"",
       manpowerUnit:"",
       manpowerRate:""
      })
    }
    let error_arr=this.state.errorindex
    error_arr.push(false)
    this.setState({
      materials:prev,
      errorindex:error_arr,
      materialCount: this.state.materialCount + 1,
    })
    console.log("previs state is ",prev,this.state.materials)

  };

  onSubmit = e => {
    e.preventDefault();

    const currmasterList = this.state.materials;
    let errorfound = false;
    this.state.errorindex.forEach(val => {
      if (val) {
        errorfound = true;
        alert(`please enter valid ${this.state.itemName} name`);

        return;
      }
    });
    if (this.state.itemName === "Material")
      this.state.materials.forEach(material => {
        if (
          material.materialName.trim() === "" ||
          material.materialUnit.trim() === ""
        ) {
          errorfound = true;
          alert("please fill the fields");
        }
      });
    if (this.state.itemName === "Equipment")
      this.state.materials.forEach(material => {
        if (
          material.equipmentName.trim() === "" ||
          material.equipmentUnit.trim() === ""
        ) {
          errorfound = true;
          alert("please fill the fields");
        }
      });
    if (this.state.itemName === "ManPower")
      this.state.materials.forEach(material => {
        if (
          material.manpowerName.trim() === "" ||
          material.manpowerUnit.trim() === ""
        ) {
          errorfound = true;
          alert("please fill the fields");
        }
      });
    if (errorfound) return;
    this.setState({
      materialCount: 1,
      materials: [],
      // isAdded: true,
    });
    console.log("currmasterlist", currmasterList);
         
    if (this.state.itemName === "Material")
      this.props.addMaterialList(currmasterList);
    if (this.state.itemName === "Equipment")
      this.props.addEquipmentList(currmasterList);
    if (this.state.itemName === "Labour")
      this.props.addLabourList(currmasterList);
    if (this.state.itemName === "ManPower")
      this.props.addManforceList(currmasterList);

    e.target.reset();
    // this.refreshPage();
  };
  render() {
    return (
      <div>
        {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
        {/* {this.state.isAdded ? this.refreshPage() : null} */}

        <div className="container mt-2" style={{ marginBottom: "1em" }}>
          <form onSubmit={this.onSubmit}>
            <Grid container spacing={2} style={{ padding: "10px" }}>
              {/* <h3 className="mt-5 mb-4">Add Materials</h3> */}
              {_.times(this.state.materialCount, i => (
                <Grid
                  container
                  style={{ padding: "10px", marginTop: "10px" }}
                  direction="row"
                  key={i}
                  spacing={2}
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name={`${this.state.itemName}Name`}
                      variant="outlined"
                      //className="m-1"
                      error={
                        this.state.errorindex[i] &&
                        this.state.errormsg
                      }
                      helperText={
                        this.state.errorindex[i] &&
                        this.state.errormsg
                      }
                      required
                      fullWidth
                      id="itemName"
                      onChange={e => this.addMaterial(e, i)}
                      label={`${this.state.itemName} name ${i + 1}`}
                      autoFocus
                    />
                  </Grid>
                  <br />
                  <br />
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name={`${this.state.itemName} unit`}
                      variant="outlined"
                      // className="m-1"
                      required
                      fullWidth
                      id="itemunit"
                      // type="number"
                      onChange={e => this.setItemUnits(e, i)}
                      label={`${this.state.itemName} unit ${i + 1}`}
                      autoFocus
                    />
                  </Grid>
                  <br />
                  <br />
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name={`${this.state.itemName} rate`}
                      variant="outlined"
                      //  className="m-1"
                      required
                      fullWidth
                      type="number"
                      id={`${this.state.itemName}unit`}
                      onChange={e => this.setItemRate(e, i)}
                      label={`${this.state.itemName} rate ${i + 1}`}
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <IconButton
                      color="secondary"
                      onClick={() => this.deleteMaterial(i)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid item style={{ marginLeft: "2px" }}>
                <Button
                  onClick={() => this.setMaterialCount()}
                  variant="outlined"
                  startIcon={<AddCircleOutline />}
                >
                  Add New {this.state.itemName}
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: "4px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ margin: "0px 5px" }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>

          <Grid container direction="row" style={{ marginTop: "2em" }}>
            <Button className="m-2" variant="contained" component="label">
              <input
                type="file"
                name="file"
                onChange={this.changeHandler}
                accept=".csv"
                hidden
              />
              Import {this.state.itemName}
            </Button>

            <Button className="m-2" color="primary" onClick={this.handleExport}>
              Export {this.state.itemName}
            </Button>
            {this.state && this.state.exportdata.length > 0 && (
              <Button className="m-2" color="primary">
                <CSVLink
                  style={{ color: "white" }}
                  data={this.state.exportdata}
                  headers={this.headers}
                  onClick={this.handleExportFile}
                >
                  Download file
                </CSVLink>
              </Button>
            )}
          </Grid>
        </div>
        <hr />
        {/* <DisplayMaterial  itemName={this.state.itemName} /> */}
        {this.state.itemName === "Material" && <DisplayMaterial />}
        {this.state.itemName === "Equipment" && <DisplayEquipment />}
        {this.state.itemName === "ManPower" && <DisplayManPower />}
      </div>
    );
  }
}
AddMaterialList.propTypes = {
  materials: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  return {
    materials: state.storeReducer,
    notifications: state.notifications,
    addMaterialsLoading: state.storeReducer.materialListLoading,
    addMaterialsFailure: state.storeReducer.materialListFailure,
    addMaterialsSuccess: state.storeReducer.materialListSuccess,
    storeData: state.materialReducer.storeData,
    equipmentData: state.storeReducer.equipmentData,
    labourData: state.storeReducer.labourData,
    manpowerData: state.storeReducer.manpowerData,
  };
};
export default connect(mapStateToProps, {
  addMaterialList,
  addEquipmentList,
  addManforceList,
})(AddMaterialList);
