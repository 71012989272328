import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem, OutlinedInput
} from "@material-ui/core";
import axios from "axios";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles({
  box: {
    maxHeight: "80vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "primary",
      outline: "1px solid slategrey",
    },
  },
  imageFull: {
    width: "100%",
  },
});



export default function FilterDPRReport(props) {
  const [activityData, setactivityData] = useState([]);
  const [activityName, setActivityName] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const DprReportFormat=['Date Wise', 'Equipment Wise','Material Wise']
  const handleChangeSelect = event => {
    const {
      target: { value },
    } = event;
    setActivityName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <Dialog
        open={props.showFilter}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
      >
        <DialogTitle id="form-dialog-title">Filter DPR REPORT</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span>Start Date</span> <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                display="block"
                inputVariant="outlined"
                margin="normal"
                placeholder="Not available"
                onChange={event => {
                  let name = "startDate";
                  props.handleInputChange(event, name);
                }}
                value={props.startDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <br />

            <span>End Date</span> <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                display="block"
                inputVariant="outlined"
                margin="normal"
                placeholder="Not available"
                value={props.endDate}
                onChange={event => {
                  let name = "endDate";
                  props.handleInputChange(event, name);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>

            <br />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Project Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={activityName}
                label="projectName"
                onChange={handleChangeSelect}
              >
                {DprReportFormat.map((item, itemIndex) => {
                      return (
                        <MenuItem value={item}>{item}</MenuItem>
                      );
                    })
}
              </Select>
            </FormControl>
            {/* <FormControl style={{ width: 250, maxWidth: 250 }}>
              <InputLabel id="demo-multiple-name-label">Activity Name</InputLabel>
              <Select
              required
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={activityName}

                onChange={handleChangeSelect}
                input={<OutlinedInput label="Name" />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 30,
                      width: 250,
                      top: 55
                    }
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
              >
                {DprReportFormat.map(name => (
                  <MenuItem key={name} value={name} style={getStyles(name, activityName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </DialogContentText>
          <DialogActions style={{ marginBottom: "1.5em", marginRight: "1em" }}>
            <Button onClick={props.handleFilter} color="secondary">
              Close
            </Button>
            <Button onClick={() => props.getDPRReport(activityName)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
