import { createMuiTheme } from "@material-ui/core/styles";

const getMuiTheme = ()  =>
createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        border: "1px solid #333",
        borderBottom: "1px solid #333",
        verticalAlign: "baseline",
        height: "100%",
        padding: "4px !important",
      },
    },
    MuiTableFooter:{
      root: {
        border: "1px solid #333",
        borderBottom: "1px solid #333",
        verticalAlign: "baseline",
        height: "100%",
        padding: "4px",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "2px !important",
        padding: "0px",
      },
    },
    MuiIconButton:{
      root: {
        padding: "4px",    
        textAlign: 'left'
      },
    }
  },
});

  export default getMuiTheme;