import React from 'react'
import {
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,TableFooter
} from "@material-ui/core";
import Style from "./style.module.scss";
export default function MisHeader(props) {
    return (
        <div>
               <Grid container className={Style.header___top}>
          <Typography
            variant="h4"
            color="textSecondary"
            style={{ marginLeft: "0.5em", color: "#fff" }}
          >
            Enbuild
          </Typography>
        </Grid>
        <Grid container className={Style.container___info}>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Generated By
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {global.config.secureStorage.getItem("user_fullname")}
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Generated On
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {new Date(new Date()).toISOString().split("T")[0]}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Start Date
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {props.startDate}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              End Date
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {props.endDate}
            </Typography>
          </Grid>
        </Grid>




 
        <hr />
        </div>
    )
}