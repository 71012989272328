import { SUCCESS_GET_WBS, ERROR_GET_WBS, LOADING_WBS,CLEARREPORT } from "./wbsTypes";

const initialState = {
  loading: false,
  newWbs: [],
  isError: false,
  csvData:[]
};
const misReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_WBS:
      return {
        ...state,
        loading: true,
      };
    case ERROR_GET_WBS:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case SUCCESS_GET_WBS:
      return {
        ...state,
        newWbs: action.payload.newWbs?action.payload.newWbs:action.payload,
        csvData: action.payload.csvData?action.payload.csvData:action.payload,
        loading: false,
      };
    case CLEARREPORT:
      return {
        ...state,
        newWbs: [],
        csvData: [],
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default misReducer;
