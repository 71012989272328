import React, { useState, useEffect } from "react";
import {
  InputLabel,
  TextField,
  Grid,
  Button,
  IconButton,
  Link,
} from "@material-ui/core";
import {
  Save,
  Cancel,
  Add as AddIcon,
  AddCircleOutline,
  Delete,
  LibraryAdd,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import Style from "./style.module.scss";
import { isInteger } from "lodash";
import _ from "lodash";

const AddMaterial = props => {
  const [open, setOpen] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [toggleText, setToggleText] = useState(false);
  const loading = open && materials.length === 0;
  const [numberOfMaterial, setNumberOfMaterial] = useState(1);

  const [materialReq, setmaterialReq] = useState([]);



  const handleMaterialAddCount = () => {
    setNumberOfMaterial(numberOfMaterial + 1);
  };

  const handleMaterialDeleteCount = index => {
    if (numberOfMaterial === 1) return;
    let temp = materialReq;
    temp.splice(index, 1);
    setmaterialReq(temp);
    setNumberOfMaterial(numberOfMaterial - 1);
  };

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    var tempMaterials = new Set();
    (() => {
      props.storeData.map(ele => {
        return tempMaterials.add(ele.materialName);
      });
      setMaterials(Array.from(tempMaterials));
    })();
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setMaterials([]);
    }
  }, [open]);

  return (
    <div>
      <section className={Style.create__material}>

      <h1 className={Style.add__material__header}>
            Add Materials To Store
          </h1>

          <Button
                variant="outlined"
                size="small"
                type="submit"
                href="/addMaterial"
              >
                View Material List
              </Button>
              <hr/>
        <form
          onSubmit={event => {
            event.preventDefault();
            props.saveMaterial(materialReq);
          }}
        >
      

          {_.times(numberOfMaterial, index => {
            let indexValue = index + 1;
            return (
              <>
                <Grid container style={{ marginTop: "20px" }}>
                  <Grid item xs={10}>
                    <Autocomplete
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      id="autoComplete"
                      name="materialName"
                      onChange={(event, newValue) => {
                        let RequsitionArr = [...materialReq];
                        RequsitionArr[index] = {
                          materialName: newValue,
                        };
                        setmaterialReq(RequsitionArr);
                      }}
                      value={
                        typeof materialReq[index] === "object" &&
                        materialReq[index] !== null
                          ? materialReq[index].materialName
                          : ""
                      }
                      options={materials}
                      getOptionLabel={ele => ele}
                      renderInput={params => (
                        <TextField
                          {...params}
                          fullWidth
                          label={"Select Material"}
                          variant="outlined"
                        />
                      )}
                    />

                    <br />
                    <TextField
                      name="materialQuantity"
                      id="materialQuantity"
                      margin="dense"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      onChange={event => {
                        console.log(materialReq);
                        let RequsitionArr = [...materialReq];
                        RequsitionArr[index] = {
                          materialName: RequsitionArr[index].materialName,
                          materialQuantity: event.target.value,
                          materialUnit: RequsitionArr[index].materialUnit,
                        };
                        setmaterialReq(RequsitionArr);
                      }}
                      label="Quantity"
                      value={
                        typeof materialReq[index] === "object" &&
                        materialReq[index] !== null
                          ? materialReq[index].materialQuantity
                          : ""
                      }
                      type="number"
                      required
                      fullWidth
                    />
                    <TextField
                      style={{ marginTop: "20px" }}
                      name="materialUnit"
                      id="materialUnit"
                      margin="dense"
                      variant="outlined"
                      label="Unit"
                      InputLabelProps={{ shrink: true }}
                      onChange={event => {
                        let RequsitionArr = [...materialReq];
                        RequsitionArr[index] = {
                          materialName: RequsitionArr[index].materialName,
                          materialQuantity:
                            RequsitionArr[index].materialQuantity,
                          materialUnit: event.target.value,
                        };
                        setmaterialReq(RequsitionArr);
                      }}
                      value={
                        typeof materialReq[index] === "object" &&
                        materialReq[index] !== null
                          ? materialReq[index].materialUnit
                          : ""
                      }
                      type="text"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <IconButton
                      color="secondary"
                      className="mt-4"
                      onClick={() => handleMaterialDeleteCount(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
                <hr />
                {numberOfMaterial - 1 === index ? (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <IconButton
                      color="primary"
                      className="mt-4"
                      onClick={() => handleMaterialAddCount(index)}
                    >
                      <AddCircleOutline />
                    </IconButton>
                  </Grid>
                ) : (
                  <Grid item xs={6}></Grid>
                )}
              </>
            );
          })}

          <br />

          <Grid justify="center" container>
            <Grid className="mr-4" item>
              <Button
                variant="contained"
                size="large"
                type="submit"
                startIcon={<Save />}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                size="large"
                onClick={props.toggleCreateNewMaterial}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </section>
    </div>
  );
};
export default AddMaterial;
