import React, { useState } from "react";
import useStyles from "./DrawerStyle";
import {
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  SwipeableDrawer,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Link as ScrollLink } from "react-scroll";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { drawerDataUtil } from "./DrawerData";
import DrawerItemWithContent from "./DrawerItemWithContent";

function ClippedDrawer(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [drawerItems, setDrawerItems] = useState(() => {
    const role = global.config.secureStorage.getItem("role");
    return drawerDataUtil(role);
  });
  const his = useHistory();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <SwipeableDrawer
        anchor="left"
        className={classes.drawer}
        variant="temporary"
        open={props.isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onOpen={() => {
          props.toggleDrawer();
        }}
        onClose={() => {
          props.toggleDrawer();
        }}
      >
        <Toolbar />
        <List className={classes.drawerContainer}>
          {/* <div style={{ margin: "0 10px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Project Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={props.projectName}
                label="projectName"
                onChange={props.handleProjectName}
              >
                {props.userProjects
                  ? props.userProjects.map((item, itemIndex) => {
                      return (
                        <MenuItem value={item}>{item.projectName}</MenuItem>
                      );
                    })
                  : "No Projects"}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sub Project Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label1"
                id="demo-simple-select1"
                // value={props.subprojectName}
                label="Age"
                onChange={props.handleSubProjectName}
              >
                {console.log(props.subprojects)}
                {props.subprojects
                  ? props.subprojects.map((item, itemIndex) => {
                      return (
                        <MenuItem value={item}>{item.subProjectName}</MenuItem>
                      );
                    })
                  : "No Projects Selected"}
              </Select>
            </FormControl>

            <Button
              fullWidth
              // onClick={props.changeCreds}
              style={{ margin: "10px 0" }}
            >
              Go
            </Button>
          </div>

          <Divider /> */}
          {drawerItems.map((item, index) => {
            return (
              <>
                {his.location.pathname !== "/" ? (
                  <>
                    {item.content ? (
                      <DrawerItemWithContent
                        item={item}
                        toggleDrawer={props.toggleDrawer}
                      />
                    ) : (
                      <Link
                        to={item.route}
                        style={{ textDecoration: "none" }}
                        key={item.name}
                      >
                        <ListItem button onClick={props.toggleDrawer}>
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <span className={classes.linkDrawer}>
                            {item.name}
                          </span>
                        </ListItem>
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    {item.content ? (
                      <DrawerItemWithContent
                        item={item}
                        toggleDrawer={props.toggleDrawer}
                      />
                    ) : (
                      <ScrollLink
                        spy={true}
                        smooth={true}
                        to={item.route}
                        style={{ textDecoration: "none" }}
                        key={item.name}
                      >
                        <ListItem button onClick={props.toggleDrawer}>
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <span className={classes.linkDrawer}>
                            {item.name}
                          </span>
                        </ListItem>
                      </ScrollLink>
                    )}
                  </>
                )}
              </>
            );
          })}
        </List>
        <Divider />
      </SwipeableDrawer>
      {his.location.pathname !== "/" && his.location.pathname.includes('super-project')==false ? (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Typography className={classes.projectName} variant="h6" >
            <strong>Project Name: {global.config.secureStorage.getItem("projectName")}</strong>
          </Typography>
        </div>) : ''}
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ClippedDrawer);
