import { LOADING_WBS, SUCCESS_GET_WBS, ERROR_GET_WBS, CLEARREPORT } from "./wbsTypes";
import axios from "axios";

const loadingActionHelper = () => {
  return {
    type: LOADING_WBS,
  };
};
const successWBSActionHelper = data => {
  return {
    type: SUCCESS_GET_WBS,
    payload: data,
  };
};
const errorActionHelper = error => {
  return {
    type: ERROR_GET_WBS,
    error,
  };
};
export const clearReport = () => async dispatch => {
  try {
    dispatch({
      type: CLEARREPORT
    });
  } catch (error) {

  }

}
export const getWbs = (filterArr, sDate, eDate) => async dispatch => {
  dispatch(loadingActionHelper());
  //
  try {
    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const superproject = global.config.secureStorage.getItem("superproject");

    var getProjectIds = await axios.get(
      `${global.config.backendURL}/wbs/getProjectIds/${superproject}`
    );
    let projectsResults = []
    for (let index = 0; index < getProjectIds.data.length; index++) {
      let projectId = getProjectIds.data[index].id
      let projectName = getProjectIds.data[index].project

      var newData = [];

      var response = await axios.get(
        `${global.config.backendURL}/wbs/getActivities/${subprojectId}/${projectId}`
        , {
          params: {
            sDate: sDate,
            eDate: eDate,
            filterArr: filterArr
          }
        });

      newData = response.data;
      projectsResults.push({ projectId:projectId,projectName: projectName, data: newData })
    }


    dispatch(successWBSActionHelper(projectsResults));
  } catch (error) {
    dispatch(errorActionHelper(error.message));
  }
};
export const getDPRWbs = (sDate, eDate) => async dispatch => {
  dispatch(loadingActionHelper());

  try {
    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const superproject = global.config.secureStorage.getItem("superproject");

    var getProjectIds = await axios.get(
      `${global.config.backendURL}/wbs/getProjectIds/${superproject}`
    );
    let projectsResults = []
    for (let index = 0; index < getProjectIds.data.length; index++) {
      let projectId = getProjectIds.data[index].id
      let projectName = getProjectIds.data[index].project

      var newData = [];

      var response = await axios.get(
        `${global.config.backendURL}/dpr/mis/${subprojectId}/${projectId}`
        , {
          params: {
            sDate: sDate,
            eDate: eDate,
          }
        });

      newData = response.data;
      projectsResults.push({ projectName: projectName, data: newData })
    }


    dispatch(successWBSActionHelper(projectsResults));
  } catch (error) {
    dispatch(errorActionHelper(error.message));
  }
};

export const getMaterialsWbs = (sDate, eDate, ReportFormat) => async dispatch => {
  dispatch(loadingActionHelper());

  try {
    // const projectId = global.config.secureStorage.getItem("projectId");
    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const superproject = global.config.secureStorage.getItem("superproject");

    var getProjectIds = await axios.get(
      `${global.config.backendURL}/wbs/getProjectIds/${superproject}`
    );
    let projectsResults = []
    for (let index = 0; index < getProjectIds.data.length; index++) {
      let projectId = getProjectIds.data[index].id
      let projectName = getProjectIds.data[index].project

      var newData = [];
      var response = await axios.get(
        `${global.config.backendURL}/dpr/materialsReport/${subprojectId}/${projectId}`
        , {
          params: {
            sDate: sDate,
            eDate: eDate,
            ReportFormat: ReportFormat
          }
        });

      newData = response.data;
      projectsResults.push({ projectName: projectName, data: newData })
    }
    dispatch(successWBSActionHelper(projectsResults));
  } catch (error) {
    dispatch(errorActionHelper(error.message));
  }
};

export const getLaboursWbs = (sDate, eDate, ReportFormat) => async dispatch => {
  dispatch(loadingActionHelper());

  try {
    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const superproject = global.config.secureStorage.getItem("superproject");

    var getProjectIds = await axios.get(
      `${global.config.backendURL}/wbs/getProjectIds/${superproject}`
    );
    let projectsResults = []
    for (let index = 0; index < getProjectIds.data.length; index++) {
      let projectId = getProjectIds.data[index].id
      let projectName = getProjectIds.data[index].project

      var newData = [];
      var response = await axios.get(
        `${global.config.backendURL}/dpr/laboursReport/${subprojectId}/${projectId}`
        , {
          params: {
            sDate: sDate,
            eDate: eDate,
            ReportFormat: ReportFormat
          }
        });

      newData = response.data;
      projectsResults.push({ projectName: projectName, data: newData })
    }

    dispatch(successWBSActionHelper(projectsResults));
  } catch (error) {
    dispatch(errorActionHelper(error.message));
  }
};
export const getcontractorRaWbs = (sDate, eDate, ReportFormat) => async dispatch => {
  dispatch(loadingActionHelper());

  try {

    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const superproject = global.config.secureStorage.getItem("superproject");

    var getProjectIds = await axios.get(
      `${global.config.backendURL}/wbs/getProjectIds/${superproject}`
    );
    let projectsResults = []
    for (let index = 0; index < getProjectIds.data.length; index++) {
      let projectId = getProjectIds.data[index].id
      let projectName = getProjectIds.data[index].project

      var newData = [];

      var response = await axios.get(
        `${global.config.backendURL}/dpr/contractorRaReport/${subprojectId}/${projectId}`
        , {
          params: {
            sDate: sDate,
            eDate: eDate,
            ReportFormat: ReportFormat
          }
        });

      newData = response.data;
      projectsResults.push({ projectName: projectName, data: newData })
    }




    dispatch(successWBSActionHelper(projectsResults));
  } catch (error) {
    dispatch(errorActionHelper(error.message));
  }
};
export const getStoreWbs = (sDate, eDate, ReportFormat) => async dispatch => {
  dispatch(loadingActionHelper());

  try {
    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const superproject = global.config.secureStorage.getItem("superproject");

    var getProjectIds = await axios.get(
      `${global.config.backendURL}/wbs/getProjectIds/${superproject}`
    );
    let projectsResults = []
    for (let index = 0; index < getProjectIds.data.length; index++) {
      let projectId = getProjectIds.data[index].id
      let projectName = getProjectIds.data[index].project

      var newData = [];

      var response = await axios.get(
        `${global.config.backendURL}/wbs/storeReport/${projectId}`
        , {
          params: {
            sDate: sDate,
            eDate: eDate,
          }
        });

      newData = response.data;
      projectsResults.push({ projectName: projectName, data: newData })
    }

    dispatch(successWBSActionHelper(projectsResults));
  } catch (error) {
    dispatch(errorActionHelper(error.message));
  }
};




export const getSupplierWbs = ( sDate, eDate,ReportFormat) => async dispatch => {
  dispatch(loadingActionHelper());
  
  try {

    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const superproject = global.config.secureStorage.getItem("superproject");

    var getProjectIds = await axios.get(
      `${global.config.backendURL}/wbs/getProjectIds/${superproject}`
    );
    let projectsResults = []
    let csvData = []
    for (let index = 0; index < getProjectIds.data.length; index++) {
      let projectId = getProjectIds.data[index].id
      let projectName = getProjectIds.data[index].project

      var newData = [];

      var response = await axios.get(
        `${global.config.backendURL}/dpr/supplierReport/${subprojectId}/${projectId}`
        , {
          params: {
            sDate: sDate,
            eDate:eDate,
            ReportFormat:ReportFormat
          }
        });

      newData = response.data;

      var responseCSVData = await axios.get(
        `${global.config.backendURL}/wbs/supplierReportCSV/${subprojectId}/${projectId}`
        , {
          params: {
            sDate: sDate,
            eDate:eDate,
            ReportFormat:ReportFormat
          }
        });
        csvData.push(...responseCSVData.data.map(item => ({ ...item, projectName })));
      

        if(newData!=[])
      projectsResults.push({ projectName: projectName, data: newData })
    }
    dispatch({
      type: SUCCESS_GET_WBS,
      payload: { newWbs:projectsResults, csvData },
    });
  } catch (error) {
    dispatch(errorActionHelper(error.message));
  }
};